import React, { useEffect, useState } from "react";
import { Form, Input, Button } from "antd";
import log from "cslog";
import { startCase } from "lodash"
import { useSingleItem } from "../../_actions/app/items";
import PhotoButton from "../../qforms/standard_elements/PhotoButton";

function PresetHumanInput({ data, onChange, onGenerate }) {
    const { input_data, prefill } = data;
    const { isSuccess, isLoading, data: dataSchema } = useSingleItem(
        "qdb_creation_schema",
        `/${input_data?.qureal_schema_id}`,
        !!input_data?.qureal_schema_id
    )

    const [initialValues, setInitialValues] = useState({});
    const [form] = Form.useForm();

    log.d(data, "data in PresetHumanInput")
    log.d(dataSchema, "dataSchema in PresetHumanInput")
    log.d(prefill, "prefill in PresetHumanInput")

    useEffect(() => {
        if (input_data) {
            const { used_fields, default: defaultValues } = input_data;

            if (prefill) {
                setInitialValues(prefill)
                return;
            }

            // If used_fields are defined, filter the default values to only include those fields
            const filteredValues = used_fields
                ? used_fields.reduce((acc, field) => {
                    if (defaultValues && defaultValues[field]) {
                        acc[field] = defaultValues[field];
                    }
                    return acc;
                }, {})
                : defaultValues || {};

            setInitialValues(filteredValues);
        }
    }, [input_data]);

    useEffect(() => {
        if (initialValues) {
            form.setFieldsValue(initialValues);
        }
    }, [initialValues, form]);

    const handleValuesChange = (changedValues, allValues) => {
        onChange && onChange(allValues);
    };

    const handleGenerate = () => {
        form.validateFields()
            .then((values) => {
                log.d("Generated values:", values);
                onGenerate && onGenerate(values); // Pass the updated values to the onGenerate callback
            })
            .catch((error) => {
                log.e("Validation failed:", error);
            });
    };

    const renderField = (field) => {
        const { name, label, itype } = field;
        switch (itype) {
            case "img":
                return <Form.Item name={name} label={label}>
                    {/* <label>{startCase(field_id)}</label> */}
                    <PhotoButton
                        field={{
                            collection: "qqq",
                            field: name,
                        }}
                        width="150px"
                        height="150px"
                        objectFit="contain"
                        show_placeholder={true}
                        background="white"
                        enable_remove_bg={false}
                    />
                </Form.Item>
            case "textarea":
                return (
                    <Form.Item name={name} label={label} key={name}>
                        <Input.TextArea rows={4} />
                    </Form.Item>
                );
            case "number":
                return (
                    <Form.Item name={name} label={label} key={name}>
                        <Input type="number" />
                    </Form.Item>
                );
            case "text":
            default:
                return (
                    <Form.Item name={name} label={label} key={name}>
                        <Input />
                    </Form.Item>
                );
        }
    };

    if (!isSuccess) {
        return <div>Loading preset...</div>;
    }


    const fields = Object.keys(dataSchema?.true_schema?.schema?.properties).map((field_key) => {
        const field = dataSchema.true_schema.schema.properties[field_key]
        return ({
            name: field_key,
            label: startCase(field_key),
            itype: field?.itype || "text",
        })
    })




    return (
        <div>
            <Form
                form={form}
                layout="vertical"
                onValuesChange={handleValuesChange}
            >
                {fields.map((field) => renderField(field))}
                {/* <Form.Item>
                    <Button type="primary" onClick={handleGenerate}>
                        Generate
                    </Button>
                </Form.Item> */}
            </Form>
        </div>
    );
}

export default PresetHumanInput;
