import styles from "./styles/MyCreations.module.css";
import React, { useEffect, useState } from "react";
import { useCreateItemMutation, useItemsFine, useUpdateItemsMutation } from "../actions/app/items";
import log from "cslog";
import Loading from "../common/Loading";
import { SingleItem } from "../qmade/creator/SingleItem";
import BlankBox from "../common/BlankBox";
import ActionBox from "../common/ActionBox";
import { Button, Input, Modal, Select, Pagination } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { startCase } from "lodash";
import { Link, useRouteMatch } from "react-router-dom";
import NewCard from "./NewCard";
import useMedia from "react-use/lib/useMedia";
import PreviewTemplate from "../qmade/creator/PreviewTemplate";
import classNames from "classnames"
import { getViewerURL } from "../_helpers/editor";
import { useDebounce } from "../utils/input";
import { StatusDropdown } from "../qmade/creator/CommentsBox";

const { Search } = Input;

const CREATION_MAPPING = {
    website: "Website",
    magazine: "Magazine",
    qdf: "QDF",
    singlepage: "Social Media creation",
    packaging_box: "Packaging",
    document: "Document"
};

const CREATION_TEXT = {
    website: "Create awesome stunning responsive dynamic websites.",
    magazine: "Create beautiful magazine websites.",
    qdf: "Create your own QDF with our QDF Builder.",
    singlepage: "Create beautiful social media pages.",
    packaging_box: "Create your own packaging boxes.",
    document: "Create document pdf effortlessly."
}

const CREATION_CARD_WIDTH = {
    // all_creation: 150,
    all_creation: 200,
    website: 250,
    slider: 150,
    magazine: 150,
    qdf: 150,
    singlepage: 150,
    packaging_box: 150,
    document: 140
};

const per_page = 50;

function MyCreations({ type, me = {}, user = "$CURRENT_USER" }) {
    const isMobile = useMedia("(max-width: 700px)");
    // const isMobile = false;

    const [search_query, setSearchQuery] = useState("");
    const debounced_query = useDebounce(search_query, 500)
    const [total, setTotal] = useState(1);
    const [num_page, setNumPage] = useState(1);
    const [page_no, setPageNo] = useState(1);
    const [status, setStatus] = useState("none")
    const [published_type, setPublishedType] = useState("all");


    const match = useRouteMatch();
    const [selected_folder, setSelectedFolder] = useState("home");
    const filter = [
        {
            _or: [
                { user_created: { _eq: user } },
                { collaborators: { directus_users_id: { _eq: user } } },
            ],
        },
        ...(user !== "$CURRENT_USER" ? [] : [{
            _or: [
                { organization: { _eq: me?.selected_org?.id } },
                { organization: { _null: true } },
            ],
        }]),
        ...(type === "all_creation" ? [] : [{ type: { _eq: type } }]),
        // { type: { _eq: type } },
        { archived: { _eq: false } },
        ...(debounced_query ? [{ title: { _contains: debounced_query } }] : []),
        ...((status !== undefined && status !== "none") ? [{ comments: { status: { _eq: status } } }] : [])
        // (selected_folder ? { folder: { _eq: selected_folder } } : { folder: { _null: true } })
    ]

    log.d(filter, "Filter in MyCreations");
    const {
        data: dataItems,
        isLoading: loadingItems,
        isSuccess: successItems,
        remove: removeItems
    } = useItemsFine(
        "qp_creation",
        // "fields=id,title,slug,type,preview,date_updated,collaborators.*&sort=-date_updated&filter[archived][_eq]=false&filter[user_created][_eq]=$CURRENT_USER",
        // type === "all_creation" ?
        // `fields=id,title,slug,type,creation_type.name,preview,date_updated,collaborators.*&sort=-date_updated&filter={"_and":[{"_or":[{"user_created":{"_eq":"$CURRENT_USER"}},{"collaborators":{"directus_users_id":{"_eq":"$CURRENT_USER"}}}]},{"archived":{"_eq":false}}]}` :
        // `fields=id,title,slug,type,creation_type.name,preview,date_updated,collaborators.*&sort=-date_updated&filter={"_and":[{"_or":[{"user_created":{"_eq":"$CURRENT_USER"}},{"collaborators":{"directus_users_id":{"_eq":"$CURRENT_USER"}}}]},{"type":{"_eq":"${type}"}},{"archived":{"_eq":false}}]}`,
        `fields=id,title,slug,type,creation_type.name,preview,date_updated,templates,collaborators.*,organization,inputs,used_schemas.qdb_creation_schema_id.id,used_schemas.qdb_creation_schema_id.name,used_schemas.qdb_creation_schema_id.user_prompt_question&sort=-date_updated&filter=${JSON.stringify({
            "_and": [
                ...filter,
                ...(selected_folder === "home" ? [{ folder: { _null: true } }] : selected_folder === "all" ? [] : [{ folder: { _eq: selected_folder } }]),
                ...(published_type === "published" ? [{ "count(templates)": { _gt: 0 } }] : []),
                ...(published_type === "unpublished" ? [{ "count(templates)": { _eq: 0 } }] : []),
            ]
        })}&meta=filter_count&limit=${per_page}&offset=${per_page * (page_no - 1)
        }`,
        true, false, true
    );
    const [selected_item, setSelectedItem] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [selected_creations, setSelectedCreations] = React.useState([]);

    log.d(dataItems, "MyCreations data");

    useEffect(() => {
        if (successItems) {
            const total_records = dataItems?.meta?.filter_count;
            if (total_records > 0) {
                setTotal(total_records);
                setNumPage(Math.ceil(total_records / per_page));
            }
            console.log("User Response", dataItems);
        }
    }, [successItems, dataItems]);


    useEffect(() => {
        if (type && successItems) {
            removeItems();
        }
    }, [type]);


    return (
        <div style={{
            paddingBottom: "50px"
        }}>
            {/* <p>Type: {type}</p> */}
            {/* <div id="preview_drawper_base"></div> */}
            <div className={styles.top_bar}>
                <FolderBar
                    selected_folder={selected_folder}
                    selectFolder={setSelectedFolder}
                    me={me}
                    user={user}
                />
                <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                    {["designer", "reviewer"].includes(me?.irole) && <Select
                        style={{ width: "150px" }}
                        onChange={(value) => setPublishedType(value)}
                        value={published_type}
                    >
                        <Select.Option value="all">All</Select.Option>
                        <Select.Option value="unpublished">⚪ Unpublished</Select.Option>
                        <Select.Option value="published">🟢 Published</Select.Option>
                    </Select>}
                    {["designer", "reviewer"].includes(me?.irole) && <StatusDropdown
                        value={status}
                        onChange={setStatus}
                    />}
                    <Search
                        placeholder="Search"
                        style={{ width: "200px" }}
                        allowClear
                        onChange={(e) => {
                            setSearchQuery(e.target.value);
                            setPageNo(1);
                        }}
                        onSearch={(value) => {
                            setSearchQuery(value);
                            setPageNo(1);
                        }}
                        onClear={() => {
                            setSearchQuery("");
                            setPageNo(1);
                        }}
                    />
                </div>
            </div>
            {loadingItems && <Loading />}
            {successItems && dataItems?.data?.length > 0 && (
                <div
                    // className={isMobile ? styles.grid_wrapper_mobile : styles.gridWrapper}
                    className={styles.gridWrapper}
                    style={isMobile ? {} : {
                        gridTemplateColumns: `repeat(auto-fill, minmax(${CREATION_CARD_WIDTH[type]}px, 1fr))`,
                    }}
                >
                    {/* <h4>Test: num_page: {num_page}, total: {total}</h4> */}
                    {/* <NewCard type={type} path={match.path} /> */}
                    {dataItems?.data?.map((item, index) => {
                        return <SingleItem
                            key={item.id}
                            index={index}
                            item={item}
                            me={me}
                            isMobile={isMobile}
                            onView={() => {
                                if (item?.type === "website") {
                                    window.open(getViewerURL(item.slug, true), '_blank');
                                } else {
                                    setOpen(true);
                                    setSelectedItem(item);
                                }
                            }}
                            enable_selection={true}
                            onSelect={() => {
                                setSelectedCreations(old_selected => [...old_selected, item.id]);
                            }}
                            onDeSelect={() => {
                                setSelectedCreations(old_selected => old_selected.filter(id => id !== item.id));
                            }}
                            selected={selected_creations.includes(item.id)}
                        />;
                    })}
                    {selected_item && <PreviewTemplate
                        item={selected_item}
                        open={open}
                        setOpen={setOpen}
                        is_template={false}
                        slug_field="slug"
                        enable_img_preview={false}
                        me={me}
                        isMobile={isMobile}
                    />}
                    {selected_creations.length > 0 && <SelectionBar
                        selected_creations={selected_creations}
                        setSelectedCreations={setSelectedCreations}
                        user={user}
                    />}
                </div>
            )}
            {successItems && total > per_page && <Pagination current={page_no} onChange={new_page_no => setPageNo(new_page_no)} total={total} pageSize={per_page}
                showTitle={false} showSizeChanger={false}
            />}

            {successItems && dataItems?.data?.length === 0 && (
                <ActionBox
                    icon={<i className="fas fa-globe"></i>}
                    title={`Create Your First ${CREATION_MAPPING[type] || "Creation"} Today`}
                    // subtitle="Create Your First Website Today"
                    content={CREATION_TEXT[type]}
                    action={
                        // <Link to={`/creatives/new/templates?type=${type}`}>
                        <Link to={`/creatives/new`}>
                            <Button type="primary">
                                Create {CREATION_MAPPING[type]} Now
                                <ArrowRightOutlined />
                            </Button>
                        </Link>
                    }
                />
            )}
        </div>
    );
}

export default MyCreations;


function FolderBar({ selected_folder = null, selectFolder = () => { }, me = {}, user = "$CURRENT_USER" }) {
    // const [selected_folder, setSelectedFolder] = useState("all");

    const [open, setOpen] = React.useState(false);
    const [new_folder_name, setNewFolderName] = useState("")

    const { isSuccess, isLoading, data: dataFolders, refetch: refetchFolders } = useItemsFine("qdb_creation_folder", `fields=id,name&filter[user_created][_eq]=${user}`, true);
    const mutation_create = useCreateItemMutation(() => { }, () => { }, () => {
        setOpen(false);
        setNewFolderName("")
    });

    useEffect(() => {
        refetchFolders();
    }, [me?.id])


    return <div style={{
        display: "flex",
        flexDirection: "row",
        gap: "10px",
        // paddingBottom: "15px",
        flexWrap: "wrap"
    }}>
        <div
            style={{
                padding: "5px 10px",
                borderRadius: "5px",
                border: "1px dashed #ddcb79",
                cursor: "pointer",
            }}
            onClick={() => setOpen(true)}
        >
            Create New Folder
        </div>
        {/* <div>selected: {selected_folder}</div> */}
        <FolderItem
            item={{
                id: "home",
                name: "Home"
            }}
            selected={selected_folder === "home"}
            selectFolder={() => selectFolder("home")}
        />
        <FolderItem
            item={{
                id: "all",
                name: "All"
            }}
            selected={selected_folder === "all"}
            selectFolder={() => selectFolder("all")}
        />
        {isSuccess && dataFolders.map(item => <FolderItem
            key={item.id}
            item={item}
            selected={selected_folder === item.id}
            selectFolder={selectFolder} />)}

        <Modal
            title="Create new folder"
            open={open}
            onOk={() => {
                mutation_create.mutate({
                    collection: "qdb_creation_folder",
                    data: {
                        name: new_folder_name
                    }
                })
            }}
            okText="Create"
            okButtonProps={{
                disabled: new_folder_name?.length < 2
            }}
            confirmLoading={mutation_create.isLoading}
            onCancel={() => setOpen(false)}
        >
            <Input
                placeholder="Enter folder name"
                onChange={(e) => setNewFolderName(e.target.value)}
                value={new_folder_name}
            />
        </Modal>
    </div>
}

function FolderItem({ item, selected = false, selectFolder = () => { } }) {
    return <div
        className={classNames({
            [styles.folder]: true,
            [styles.folder_selected]: selected
        })}
        onMouseDown={() => selectFolder(item?.id)}
    >
        <i className="fas fa-folder" ></i>
        {item.name}
    </div>
}


function SelectionBar({ selected_creations, setSelectedCreations, user = "$CURRENT_USER" }) {
    const { isSuccess, isLoading, data: dataFolders } = useItemsFine("qdb_creation_folder", `fields=id,name&filter[user_created][_eq]=${user}`, true);

    const [open, setOpen] = useState(false);
    const [selected_folder_to_move, setSelectedFolderToMove] = useState(null)
    const mutation_update = useUpdateItemsMutation(
        () => { }, () => { },
        () => {
            setOpen(false);
            setSelectedFolderToMove(null);
            setSelectedCreations([]);
        }
    );


    const moveToFolder = () => {
        if (selected_folder_to_move && selected_creations?.length > 0) {
            const out = {
                collection: "qp_creation",
                data: {
                    keys: selected_creations,
                    data: {
                        folder: selected_folder_to_move === "home" ? null : selected_folder_to_move
                    }
                }
            }
            log.d(out, "movign iwth ")
            mutation_update.mutate(out)
        }
    }
    return <div style={{
        position: "fixed",
        bottom: 10,
        left: "50%",
        transform: "translateX(-50%)",
        background: "white",
        zIndex: 99,
        boxShadow: "0 0 10px 0 rgba(0,0,0,0.5)",
        padding: "5px 30px",
        borderRadius: "15px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "10px"
    }}>
        <h4>{selected_creations.length} selected</h4>
        <Button
            type="primary"
            style={{
                marginLeft: "auto",
            }}
            onClick={() => setOpen(true)}
        >
            Move to folder
        </Button>
        <Button
            type="primary"
            danger
            style={{
                marginLeft: "auto",
            }}
        >
            Delete
        </Button>
        <Button type="dashed" onClick={() => setSelectedCreations([])}>
            Cancel
        </Button>
        <Modal
            title="Select folder"
            open={open}
            onOk={moveToFolder}
            okText="Move"
            okButtonProps={{
                disabled: (!selected_folder_to_move || selected_creations?.length <= 0)
            }}
            confirmLoading={mutation_update.isLoading}
            onCancel={() => setOpen(false)}
        >
            {isSuccess && <Select
                loading={isLoading}
                placeholder="Select Folder"
                onChange={(value) => {
                    setSelectedFolderToMove(value)
                }}
                value={selected_folder_to_move}
                style={{ width: "100%" }}
                options={[{ id: "home", name: "Home" }, ...dataFolders].map(item => ({ value: item.id, label: item.name }))}
            />}
        </Modal>
    </div>
}