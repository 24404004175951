import React, { Fragment, useEffect, useState } from "react";
import log from "cslog";
import {
    Button,
    Checkbox,
    Col,
    Form,
    Input,
    Popover,
    Space,
    Row,
    Segmented,
} from "antd";
import OrgPicker from "./OrgPicker";
import RelationFieldStanard from "../../qforms/standard_elements/RelationFieldStandard";
import { BulbOutlined, ControlOutlined } from "@ant-design/icons";
import startCase from "lodash/startCase";
import { UserInputFormOne } from "./UserInputForm";
import { useFieldsDirect, useQdbFields } from "../../_actions/app/fields";
import { useItemsFine } from "../../_actions/app/items";
import AIModelPicker from "./AIModelPicker";
import PresetHumanInput from "./PresetHumanInput";
import styles from "./styles/TemplateAIInput.module.css";

const { TextArea } = Input;

function TemplateAIInput({
    inputs = [],
    used_schemas = [],
    user_inputs = {},
    onAISubmit,
    me,
    creation_type,
    org,
    onOrgChange,
    person,
    onPersonChange,
    isMobile,
    loadingAI,
    prefill,
    setPrefill,
}) {
    const [schemas, setSchemas] = useState({});

    useEffect(() => {
        if (used_schemas.length > 0) {
            const out = {};
            used_schemas.forEach((schema) => {
                out[schema.qdb_creation_schema_id.id] =
                    schema.qdb_creation_schema_id;
            });
            setSchemas(out);
        }
    }, [used_schemas]);

    const page_id = creation_type === "document" ? "home" : "frontSide";

    const pageInputs = inputs?.[page_id];

    log.d(schemas, "Schemas in PreviewTemplate");

    if (!pageInputs) {
        return <></>;
    }
    return (
        <div>
            {/* <h4>Customize</h4> */}
            <p>
                {Object.keys(pageInputs).map((input_id) => {
                    const input = pageInputs[input_id];
                    switch (input.block_type) {
                        case "qureal_database":
                            return (
                                <DBInput
                                    input_id={input_id}
                                    input_data={input?.data}
                                    me={me}
                                    isMobile={isMobile}
                                    org={org}
                                    onOrgChange={onOrgChange}
                                    person={person}
                                    onPersonChange={onPersonChange}
                                    prefill={prefill?.[input_id]}
                                    setPrefill={(new_values) => {
                                        setPrefill({
                                            ...prefill,
                                            [input_id]: new_values || {},
                                        });
                                    }}
                                />
                            );
                        case "qureal_preset":
                            return (
                                <PresetInput
                                    input_data={input?.data}
                                    schemas={schemas}
                                    onAISubmit={onAISubmit}
                                    loadingAI={loadingAI}
                                    user_inputs={
                                        user_inputs?.enable
                                            ? user_inputs?.data?.[input_id]
                                            : {}
                                    }
                                    data_block_id={input_id}
                                    prefill={prefill[input_id]}
                                    setPrefill={(new_values) => {
                                        setPrefill({
                                            ...prefill,
                                            [input_id]: new_values || {},
                                        });
                                    }}
                                />
                            );
                        case "ai_model":
                            return (
                                <AIModelPicker
                                    input_id={input_id}
                                    input_data={input?.data}
                                    onChange={(new_values) => {
                                        // alert(`New model: ${JSON.stringify(new_values)}`)
                                        setPrefill({
                                            ...prefill,
                                            [input_id]: new_values,
                                        });
                                    }}
                                />
                            );
                        default:
                            return (
                                <p className="q_gray">
                                    {input?.block_type} not supported yet.
                                </p>
                            );
                    }
                })}
            </p>
        </div>
    );
}

export default TemplateAIInput;

function PresetInput({
    input_data,
    schemas,
    user_inputs,
    onAISubmit,
    loadingAI,
    data_block_id,
    prefill,
    setPrefill
}) {
    const [form] = Form.useForm();
    const [input_type, setInputType] = useState("ai");

    const applied_schema = schemas?.[input_data?.qureal_schema_id];

    log.d(user_inputs, "user_inputs in PresetInput");

    const user_input_fields = user_inputs?.user_input_fields || {};
    const prefilled_values = user_inputs?.extracted_user_inputs?.success
        ? user_inputs?.extracted_user_inputs?.data
        : {};
    const user_prompt = user_inputs?.user_prompt || "";

    log.d(prefilled_values, "prefilled_values in PresetInput");

    // if (Object.keys(user_input_fields).length > 0) {
    //     return <UserInputFormOne
    //         fields={user_input_fields}
    //         prefilled_values={prefilled_values}
    //         onSubmit={(values) => {
    //             onAISubmit({
    //                 user_prompt: user_prompt,
    //                 user_filled_inputs: {
    //                     [data_block_id]: values
    //                 }
    //             })
    //         }}
    //         data_block_id={data_block_id}
    //     />
    // }

    return (
        <Fragment>
            <Segmented
                options={[
                    { label: "AI Prompt", value: "ai" },
                    { label: "Manual Input", value: "human" },
                ]}
                value={input_type}
                onChange={(val) => setInputType(val)}
                style={{ marginTop: "15px" }}
            />

            {/* {input_type === "human" && <pre>{JSON.stringify(schemas, null, 2)}</pre>} */}

            <div className={styles.chat_box_wrapper}>
                {input_type === "human" && (
                    <PresetHumanInput
                        data={{
                            schemas,
                            user_inputs,
                            input_data,
                            prefill,
                        }}
                        onChange={(new_values) => {
                            log.d(new_values, "new_values in TemplateAIInput")
                            setPrefill(new_values);
                        }}
                    />
                )}
                {input_type === "ai" &&
                    Object.keys(user_input_fields).length > 0 && (
                        <UserInputFormOne
                            fields={user_input_fields}
                            prefilled_values={prefilled_values}
                            onSubmit={(values) => {
                                onAISubmit({
                                    user_prompt: user_prompt,
                                    user_filled_inputs: {
                                        [data_block_id]: values,
                                    },
                                });
                            }}
                            data_block_id={data_block_id}
                        />
                    )}
                {input_type === "ai" &&
                    Object.keys(user_input_fields).length === 0 && (
                        <Form
                            form={form}
                            layout="vertical"
                            // onFinish={onFinish}
                            onFinish={(values) => {
                                if (values?.user_prompt?.length > 3) {
                                    onAISubmit(values);
                                }
                            }}
                            // onFinishFailed={onFinishFailed}
                            autoComplete="off"
                            style={{
                                marginTop: "10px",
                            }}
                        >
                            {/* <p>INPUT: {JSON.stringify(input_data)}</p>
        <p>APPLIED_Schema: {JSON.stringify(applied_schema)}</p> */}
                            <Form.Item
                                name="user_prompt"
                                label={
                                    applied_schema?.user_prompt_question ||
                                    "Enter input"
                                }
                                rules={[
                                    // { required: true },
                                    { type: "string", max: 200 },
                                ]}
                            >
                                <TextArea rows={4} placeholder="Enter mesage" />
                            </Form.Item>
                            <Form.Item>
                                <Space>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        loading={loadingAI}
                                        icon={<BulbOutlined />}
                                    >
                                        Generate
                                    </Button>
                                    <Button
                                        htmlType="button"
                                    // onClick={onFill}
                                    >
                                        Reset
                                    </Button>
                                </Space>
                            </Form.Item>
                        </Form>
                    )}
            </div>
        </Fragment>
    );
}

function DBInput({
    input_id,
    input_data,
    me,
    org,
    onOrgChange,
    person,
    onPersonChange,
    isMobile,
    prefill,
    setPrefill,
}) {
    log.d(org, "org in DBInput");

    let form_field = null;
    if (input_id === "current_organization") {
        form_field = (
            <OrgPicker
                me={me}
                org={org}
                onOrgChange={onOrgChange}
                isMobile={isMobile}
            />
        );
    } else if (input_id === "current_person") {
        form_field = (
            <RelationFieldStanard
                field={{
                    foreign_key_table: "qdb_person",
                    template: "{{full_name}}",
                    foreign_key_column: "id",
                }}
                value={person}
                onChange={(new_person) => onPersonChange(new_person)}
                style={{
                    width: 200,
                    marginTop: "15px",
                }}
                showSearch={!isMobile}
            />
        );
    }
    return (
        <Form layout="vertical">
            <Form.Item name="organization" label="Organization">
                {/* {form_field} */}
                {/* <pre>{JSON.stringify(prefill, null, 2)}</pre> */}
                <div
                    style={{
                        display: "flex",
                        gap: "10px",
                    }}
                >
                    {form_field}
                    {input_data?.used_fields?.length > 0 && (
                        <CustomizeDBInput
                            input_id={input_id}
                            used_fields={input_data?.used_fields}
                            value={prefill?.exclude_fields}
                            onChange={(new_values) => {
                                const new_prefill = removeUsedFields(
                                    prefill,
                                    input_data?.used_fields
                                );
                                setPrefill({
                                    ...new_prefill,
                                    exclude_fields: new_values,
                                    ...getBlankFilledValues(new_values),
                                });
                            }}
                        />
                    )}
                </div>
            </Form.Item>
        </Form>
    );
}

function getBlankFilledValues(fields) {
    // return Object.fromEntries(fields.map(field => [field, ""]))
    return Object.fromEntries(fields.map((field) => [field, "‎"]));
}

function removeUsedFields(values, used_fields) {
    return Object.fromEntries(
        Object.entries(values || {}).filter(
            ([key]) => !used_fields.includes(key)
        )
    );
}

function CustomizeDBInput({ input_id, value, onChange, used_fields }) {
    const [open, setOpen] = useState(false);

    const value_reversed = used_fields?.filter(
        (item) => !value?.includes(item)
    );

    return (
        <Popover
            title="Show / Hide fields"
            trigger="click"
            placement="bottomRight"
            open={open}
            onOpenChange={setOpen}
            content={
                <div>
                    {used_fields?.length > 0 && (
                        <Checkbox.Group
                            style={{ width: "200px" }}
                            // value={value}
                            value={value_reversed}
                            // onChange={onChange}
                            onChange={(values) =>
                                onChange(
                                    used_fields?.filter(
                                        (item) => !values?.includes(item)
                                    )
                                )
                            }
                        >
                            <Row>
                                {used_fields?.map((item) => {
                                    return (
                                        <Col
                                            key={item}
                                            // span={12}
                                            span={24}
                                        >
                                            <Checkbox value={item}>
                                                {startCase(item)}
                                            </Checkbox>
                                        </Col>
                                    );
                                })}
                            </Row>
                        </Checkbox.Group>
                    )}
                </div>
            }
        >
            <Button icon={<ControlOutlined />}></Button>
        </Popover>
    );
}

function CustomizeDBInput_old_full({ input_data, value, onChange }) {
    const [open, setOpen] = useState(false);
    const { data, isSuccess } = useItemsFine(
        "qdb_field",
        `fields=id,name,table&filter={"table":{"_eq":"${input_data?.table}"}}`,
        open
    );

    const table = input_data?.table;

    log.d(data, "Fields data");

    return (
        <Popover
            title="Show / Hide fields"
            trigger="click"
            placement="bottomRight"
            open={open}
            onOpenChange={setOpen}
            content={
                <div>
                    {isSuccess && (
                        <Checkbox.Group
                            style={{ width: "400px" }}
                            value={value}
                            onChange={onChange}
                        >
                            <Row>
                                {data?.map((item) => {
                                    return (
                                        <Col key={item.id} span={12}>
                                            <Checkbox value={item.id}>
                                                {startCase(item.name)}
                                            </Checkbox>
                                        </Col>
                                    );
                                })}
                            </Row>
                        </Checkbox.Group>
                    )}
                </div>
            }
        >
            <Button icon={<ControlOutlined />}></Button>
        </Popover>
    );
}
