import { Button, Checkbox, Divider, Drawer, Modal, Select } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { getTime } from "../../_helpers/app/dt";
import { getImageFileURL, getPublicImageURL } from "../../_helpers/image";
import {
    PREVIEW_CREATION_URL,
    PREVIEW_DOCUMENT_CREATION_URL,
    PREVIEW_DOCUMENT_TEMPLATE_URL,
    PREVIEW_TEMPLATE_URL,
} from "../../constants";
import RelationFieldStanard from "../../qforms/standard_elements/RelationFieldStandard";
import styles from "./styles/Preview.module.css";
import log from "cslog";
import { QurealViewer, useQurealActions } from "qureal-editor";

import {
    DownloadOutlined,
    ShareAltOutlined,
    SyncOutlined,
    ThunderboltOutlined,
    EyeOutlined,
    CloseOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import TemplateAIInput from "./TemplateAIInput";
import {
    useAIImageGenerator,
    useGenerateTemplatePreset,
} from "../../_actions/bot";
import GenerationLoading from "./GenerationLoading";
import { getEditURL } from "../../_helpers/editor";
import LOGO from "../../media/imgs/1_logo_horizontal.png";
import LOGO_SQUARE from "../../media/imgs/0_logo_square.png";
import UserInputFormAll from "./UserInputForm";
import { useWindowSize } from "react-use";
import CommentsBox from "./CommentsBox";

const MODAL_HEIGHT = "90vh";

function PreviewTemplate({
    item,
    trigger,
    slug_field = "id",
    is_template = false,
    enable_nav = false,
    enable_prev = false,
    enable_next = false,
    onPrevClick = () => { },
    onNextClick = () => { },
    onRedeisgnClick = () => { },
    enable_redesign = false,
    open,
    setOpen,
    enable_img_preview = true,
    me = {},
    isMobile = false,
    post_idea = null,
}) {
    const [iopen, setIOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [prefill, setPrefill] = useState({});

    const [org, setOrg] = useState(me?.selected_org?.id);
    const [person, setPerson] = useState(null);
    const [preview, setPreview] = useState(true);
    const [status, setStatus] = useState({
        download: false,
        share: false,
    });
    const [user_inputs, setUserInputs] = useState({
        enable: false,
        data: {},
    });
    const [user_prompt, setUserPrompt] = useState("");

    const { actionRef, download, share } = useQurealActions();
    const mutationImgGen = useAIImageGenerator((result, variables, context) => {
        log.d(result, "Image ai Gen success");
        log.d(variables, "Image ai Gen variables");
        log.d(context, "Image ai Gen context");
        if (result?.success) {
            const img_obj = result?.data?.data;
            const block_id = variables.block_id;
            const field_name = variables.field_name;

            setPrefill((old_prefill) => {
                const new_val = {
                    ...old_prefill,
                    [block_id]: {
                        ...old_prefill[block_id],
                        [field_name]: {
                            ...old_prefill[block_id][field_name],
                            params: img_obj,
                            value: getImageFileURL(img_obj.filename_disk),
                        },
                    },
                };
                log.d(new_val, "New prefill inside");
                return new_val;
            });
            setPreview((old_preview) => {
                return {
                    ...old_preview,
                    [block_id]: {
                        ...old_preview[block_id],
                        [field_name]: {
                            ...old_preview[block_id][field_name],
                            params: img_obj,
                            value: getImageFileURL(img_obj.filename_disk),
                        },
                    },
                };
            });
        }
    });
    const mutationAIGen = useGenerateTemplatePreset((data) => {
        log.d(data, "AI Gen success");
        if (data?.success) {
            if (data?.stop_reason === "need_user_input") {
                const uifs = data?.data || {};
                setUserInputs({
                    enable: true,
                    data: uifs,
                });
            } else {
                // send data.data to iframe
                const out = {};
                Object.keys(data.data).forEach((key) => {
                    const values = data.data[key]["data"];
                    const this_out = values;
                    Object.keys(values).forEach((field_name) => {
                        const val = values[field_name];
                        if (isDictionary(val)) {
                            if (val.hasOwnProperty("prompt")) {
                                log.d(val, "Dictionary found in AI Gen");
                                this_out[field_name] = {
                                    ...val,
                                    params: {
                                        srcType: "pixabay",
                                        id: 2099205,
                                        preview:
                                            "https://cdn.pixabay.com/photo/2017/02/26/00/31/borders-2099205_150.png",
                                        previewWidth: 130,
                                        previewHeight: 150,
                                        width: 553,
                                        height: 640,
                                        dev: "https://botapi.qureal.com/proxies/pixabay/download/2099205_553.png",
                                        full: "https://botapi.qureal.com/static/pxb/2099205_553.png",
                                    },
                                    value: "https://botapi.qureal.com/static/pxb/2099205_553.png",
                                };
                                // initiate image generation
                                mutationImgGen.mutate({
                                    config:
                                        data?.img_fields?.[field_name] || {},
                                    prompt: val.prompt,
                                    field_name: field_name,
                                    block_id: key,
                                });
                            }
                        } else {
                            this_out[key] = val;
                        }
                    });
                    out[key] = this_out;
                });

                log.d(out, "AI gen out processed");
                setPreview(out);
                setPrefill(out);
                log.p("data pushed to template");
            }
        }
    });

    useEffect(() => {
        if (iopen) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [iopen]);

    // useEffect(() => {
    //     window.onpopstate = (e) => {
    //         alert("back pressed")
    //         e.stopPropagation();
    //         if (open) {
    //             setOpen(false)
    //         }
    //     }
    // }, [])

    let url = `${is_template ? PREVIEW_TEMPLATE_URL : PREVIEW_CREATION_URL}${item[slug_field]
        }`;
    if (item?.type === "document") {
        url = `${is_template
            ? PREVIEW_DOCUMENT_TEMPLATE_URL
            : PREVIEW_DOCUMENT_CREATION_URL
            }${item[slug_field]}`;
    }

    const editor_url = `https://editor.qureal.com/${item?.id}/edit`;
    let customized = false;

    let iframe_url = `${url}?`;
    if (org) {
        iframe_url += `qi_organization=${org}`;
        customized = true;
    }
    if (person) {
        iframe_url += `&qi_person=${person}`;
        customized = true;
    }

    log.d(me, "Me in PreviewTemplate");
    log.d(item, "PreviewTemplate item");

    const onAISubmit = (data) => {
        // alert("submitting")
        const id_key = is_template ? "template_id" : "creation_id";
        const out = {
            ...data,
            [id_key]: item["id"],
        };
        log.d(out, "Submitting to AI");
        mutationAIGen.mutate(out);
    };

    useEffect(() => {
        if (post_idea) {
            onAISubmit({
                user_prompt: post_idea,
            });
        }
        // }, [post_idea])
    }, [post_idea, item]);

    log.d(prefill, "Prefill now");

    return (
        <Fragment>
            {trigger && (
                <span
                    onClick={(e) => {
                        e.preventDefault();
                        if (item?.type === "singlepage") {
                            setIOpen(true);
                        } else {
                            window.open(url, "_blank");
                        }
                    }}
                >
                    {trigger}
                </span>
            )}

            <PreviewWrapper
                trigger={trigger}
                open={open}
                setOpen={setOpen}
                iopen={iopen}
                setIOpen={setIOpen}
                isMobile={isMobile}
                // title={item.title}
                title={
                    <div className={styles.bottom_bar}>
                        <img
                            src={isMobile ? LOGO_SQUARE : LOGO}
                            className={styles.logo}
                        />

                        <div className={styles.bottom_bar_btn_group}>
                            <a href={url} target="_blank" rel="noreferrer">
                                {isMobile ? (
                                    <Button icon={<EyeOutlined />}></Button>
                                ) : (
                                    <Button icon={<EyeOutlined />}>
                                        Full page view
                                    </Button>
                                )}
                            </a>
                            <Button
                                onClick={download}
                                type="primary"
                                loading={status?.download}
                                icon={<DownloadOutlined />}
                            >
                                Download
                            </Button>
                            <Button
                                onClick={share}
                                type="primary"
                                loading={status?.share}
                                icon={<ShareAltOutlined />}
                            >
                                Share
                            </Button>
                        </div>
                    </div>
                }
            >
                <div className={styles.wrapper}>
                    <div className={styles.left_box}>
                        {/* <p>K: {item.type}:{item[slug_field]}</p> */}

                        <div className={styles.web_box}>
                            <QurealViewer
                                // slug={item.slug}
                                slug={item[slug_field]}
                                preview={preview}
                                style={{
                                    width: isMobile ? "93vw" : "85vh",
                                    height: isMobile ? "93vw" : "85vh",
                                    outline: "none",
                                    // border: "1px solid #777",
                                    // border: "1px solid transparent"
                                }}
                                organization={org}
                                person={person}
                                actionRef={actionRef}
                                type={is_template ? "template" : "creation"}
                                creation_type={item.type}
                                fit={true}
                                background="white"
                                onInit={(data) => {
                                    // alert(JSON.stringify(data))
                                }}
                                prefill={prefill}
                                onStatusUpdate={(data) => {
                                    // alert("status updated")
                                    setStatus(data);
                                }}
                            />

                            {mutationAIGen?.isLoading && (
                                <div className={styles.preview_loading_wrapper}>
                                    <GenerationLoading />
                                </div>
                            )}
                        </div>

                        {!isMobile && (
                            <div className={styles.bottom_bar}>
                                <img src={LOGO} className={styles.logo} />

                                <div className={styles.bottom_bar_btn_group}>
                                    <a
                                        href={url}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {isMobile ? (
                                            <Button
                                                icon={<EyeOutlined />}
                                            ></Button>
                                        ) : (
                                            <Button icon={<EyeOutlined />}>
                                                Full page view
                                            </Button>
                                        )}
                                    </a>
                                    <Button
                                        onClick={download}
                                        type="primary"
                                        loading={status?.download}
                                        icon={<DownloadOutlined />}
                                    >
                                        Download
                                    </Button>
                                    <Button
                                        onClick={share}
                                        type="primary"
                                        loading={status?.share}
                                        icon={<ShareAltOutlined />}
                                    >
                                        Share
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* <div className={styles.left_box}>
                        {(customized || !enable_img_preview) ?
                            <iframe
                                title={item.title}
                                src={`${iframe_url}&preview&qi_fit`}
                                width={500}
                                className={styles.web_box}
                                onLoad={() => setLoading(false)}
                            // loading="lazy"
                            /> : <img
                                className={styles.web_box}
                                src={getImageFileURL(item.preview?.filename_disk)}
                                alt="preview"
                            />}
                    </div> */}
                    <div className={styles.right_box}>
                        {!isMobile && (
                            <div className={styles.top_bar}>
                                <div className={styles.profile_pic}>PP</div>
                                <div className={styles.name_wrapper}>
                                    <div className={styles.name}>
                                        {item.title}
                                    </div>
                                    {!is_template && (
                                        <div className={styles.subtitle}>
                                            Last updated{" "}
                                            {getTime(item.date_updated, "rt")}{" "}
                                            ago
                                        </div>
                                    )}
                                </div>
                                <div className={styles.profile_actions}>
                                    {!is_template && (
                                        <a
                                            // href={editor_url}
                                            href={getEditURL(item.id)}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <Button>Edit</Button>
                                        </a>
                                    )}
                                </div>
                            </div>
                        )}

                        {/* <div className={styles.middle_pane}> */}

                        {!isMobile && (
                            <Checkbox
                                checked={preview}
                                onChange={(e) => setPreview(e.target.checked)}
                                style={{
                                    marginTop: "15px",
                                }}
                            >
                                Enable preview mode
                            </Checkbox>
                        )}

                        {/* {enable_nav && <div className={styles.page_control_wrapper}>
                            <Button disabled={!enable_prev} onClick={onPrevClick}>Previous</Button>
                            <Button disabled={!enable_next} onClick={onNextClick}>Next</Button>
                        </div>} */}

                        <div className={styles.action_wrapper}>
                            <Button
                                icon={<SyncOutlined />}
                                disabled={!enable_redesign}
                                onClick={onRedeisgnClick}
                            >
                                Redesign
                            </Button>
                            <Button
                                icon={<ThunderboltOutlined />}
                                disabled={
                                    !Object.values(
                                        item?.inputs?.[
                                        item.type === "document"
                                            ? "home"
                                            : "frontSide"
                                        ] || {}
                                    )?.some(
                                        (input) =>
                                            input?.block_type ===
                                            "qureal_preset"
                                    )
                                }
                            >
                                Rewrite
                            </Button>
                        </div>

                        <Divider />

                        <TemplateAIInput
                            inputs={item.inputs}
                            used_schemas={item.used_schemas}
                            me={me}
                            org={org}
                            creation_type={item.type}
                            onOrgChange={(val) => setOrg(val)}
                            person={person}
                            onPersonChange={(val) => setPerson(val)}
                            isMobile={isMobile}
                            onAISubmit={(values) => onAISubmit(values)}
                            loadingAI={mutationAIGen.isLoading}
                            user_inputs={user_inputs}
                            prefill={prefill}
                            setPrefill={setPrefill}
                        />
                        {/* <p>PREFILL: {JSON.stringify(prefill)}</p> */}

                        {/* <UserInputFormAll
                            enable={user_inputs.enable}
                            data={user_inputs.data}
                        /> */}

                        <div style={{ width: "100%", height: "100px", backgroundColor: "transparent" }}></div>



                        <div className={styles.bottom_pane}>
                            {!is_template && <CommentsBox creation={item} />}
                        </div>

                        {/* <pre>{JSON.stringify(item, null, 2)}</pre> */}
                    </div>
                </div>
            </PreviewWrapper>
        </Fragment>
    );
}

export default PreviewTemplate;

function PreviewWrapper({
    children,
    trigger,
    open,
    setOpen,
    iopen,
    setIOpen,
    isMobile,
    title,
}) {
    const { width, height } = useWindowSize();

    // useEffect(() => {
    //     const closeThis = () => setOpen(false);
    //     window.addEventListener("beforeunload", closeThis);
    //     return () => window.removeEventListener("beforeunload", closeThis);
    // }, [])

    if (isMobile) {
        return (
            <Drawer
                // title="Basic Drawer"
                // title={null}
                title={
                    <div className={styles.drawer_title_wrapper}>
                        {title}
                        {/* <span className={styles.drawer_close_btn} onClick={() => setOpen(false)}>X</span> */}
                        <div
                            onClick={() => setOpen(false)}
                            className={styles.drawer_close_btn_wrapper}
                        >
                            <Button icon={<CloseOutlined />}></Button>
                        </div>
                    </div>
                }
                placement="bottom"
                closable={false}
                onClose={() => setOpen(false)}
                open={open}
                key="qureal_preview_drawer"
                // size="large"
                // height="90vh"
                // height="100vh"
                height="100%"
                closeIcon={true}
            // getContainer={document.getElementById("preview_drawper_base")}
            // title={<div style={{
            //     width: "100%",
            //     height: "0px",
            //     margin: "-10px"
            // }} />}
            >
                {children}
            </Drawer>
        );
    }
    return (
        <Modal
            // title="Template Preview"
            open={trigger ? iopen : open}
            centered
            onOk={() => {
                setIOpen(false);
                if (!trigger) {
                    setOpen(false);
                }
            }}
            onCancel={() => {
                setIOpen(false);
                if (!trigger) {
                    setOpen(false);
                }
            }}
            // width={"75vw"}
            width={width > 1200 ? "1200px" : "98vw"}
            footer={null}
            style={{
                padding: "0px",
                // border: "2px dotted red"
            }}
        >
            {/* <h4>Width: {width}</h4> */}
            {children}
        </Modal>
    );
}

function isDictionary(value) {
    return (
        value !== null &&
        typeof value === "object" &&
        value.constructor === Object
    );
}
