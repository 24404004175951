import { Button } from "antd";
import { getFileURL, getImageFileURL } from "../_helpers/image";
import styles from "./styles/SingleTemplateItem.module.css";
import React from "react";
import { useUpdateItemMutation } from "../actions/app/items";


function SingleTemplateItem({ data, previewTemplate, editTemplate }) {
    const mutationUpdate = useUpdateItemMutation(() => {
        // success
    });

    return <div className={styles.wrapper}>
        <div className={styles.left_box}>
            <img alt="preview" src={getImageFileURL(data.preview?.filename_disk)} className={styles.preview} />
        </div>
        <div className={styles.right_box}>
            <div>
                <h4 className={styles.title}>{data.title}</h4>
                <div className={styles.tags_wrapper}>
                    {data.tags?.map(tag => <div className={styles.tag}>{tag.qdb_tag_id.name}</div>)}
                </div>
            </div>

            <div className={styles.tags_wrapper}>
                <Button type="primary" onClick={previewTemplate}>Preview</Button>
                <Button type="primary" onClick={editTemplate}>Edit Template</Button>
                {data.status === "draft" && <Button type="primary" loading={mutationUpdate.isLoading} onClick={() => {
                    mutationUpdate.mutate({
                        collection: "qdb_creation_template",
                        id: data?.id,
                        data: {
                            status: "published"
                        }
                    })
                }}>Publish</Button>}
                {data.status === "published" && <Button type="primary" loading={mutationUpdate.isLoading} onClick={() => {
                    mutationUpdate.mutate({
                        collection: "qdb_creation_template",
                        id: data?.id,
                        data: {
                            status: "draft"
                        }
                    })
                }}>Unpublish</Button>}
                {data.creation === null && <Button style={{ backgroundColor: "#f5cb42" }}>Link Creation</Button>}
            </div>
        </div>
    </div>
}

export default SingleTemplateItem;