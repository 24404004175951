import styles from "./styles/TemplatesGrid.module.css";
import React, { useEffect, useState } from "react";
import { useItemsFine } from "../actions/app/items";
import SingleTemplateItem from "./SingleTemplateItem";
import PreviewTemplate from "../qmade/creator/PreviewTemplate";
import TemplateEditDialog from "./TemplateEditDialog";
import startCase from "lodash/startCase";
import { Form, Pagination } from "antd";
import RelationFieldStanard from "../qforms/standard_elements/RelationFieldStandard";

const FIELDS = "id,type,title,status,preview.id,preview.filename_disk,tags.id,tags.qdb_tag_id.*,inputs,used_schemas.qdb_creation_schema_id.id,used_schemas.qdb_creation_schema_id.name,used_schemas.qdb_creation_schema_id.user_prompt_question,creation"


const per_page = 50;

function TemplatesGrid({ me, isMobile, status }) {

    const [total, setTotal] = useState(1);
    const [num_page, setNumPage] = useState(1);
    const [page_no, setPageNo] = useState(1);
    const [preset, setPreset] = useState(null);

    const { isSuccess, isLoading, data } = useItemsFine(
        "qdb_creation_template",
        `fields=${FIELDS}&filter[status][_eq]=${status}&sort=-date_created&meta=filter_count&limit=${per_page}&offset=${per_page * (page_no - 1)}${preset ? `&filter[used_schemas][qdb_creation_schema_id][id][_eq]=${preset}` : ""}`,
        true, false, true);

    const [selected_template, setSelectedTemplate] = React.useState(null);
    const [edit_template, setEditTemplate] = useState(null);


    useEffect(() => {
        setPageNo(1);
    }, [status])


    useEffect(() => {
        if (isSuccess) {
            const total_records = data?.meta?.filter_count;
            if (total_records > 0) {
                setTotal(total_records);
                setNumPage(Math.ceil(total_records / per_page));
            }
            console.log("User Response", data);
        }
    }, [isSuccess, data]);

    return <div>
        <h3>{startCase(status)} Templates</h3>
        <p>Preset_id: {preset}</p>
        <Form layout="vertical">
            <Form.Item label="Used preset">
                <RelationFieldStanard
                    // key={render_key}
                    field={{
                        foreign_key_table: "qdb_creation_schema",
                        foreign_key_column: "id",
                        junction_field: "qdb_tag_id",
                        many_table_pk: "id",
                        template: "{{name}}",
                        type: "relationO2M",
                    }}
                    fieldString="&limit=-1"
                    value={preset}
                    onChange={(val) => {
                        // alert(val);
                        setPreset(val);
                    }}
                    many={false}
                    style={{
                        width: "400px"
                    }}
                    additional_options={[]}
                />
            </Form.Item>
        </Form>

        {isSuccess && <p style={{ color: "#0000005a" }}>Showing {data?.data?.length} of total count {total}</p>}

        {isSuccess && data?.data?.length > 0 && <div className={styles.grid}>
            {data?.data?.map(item => {
                return <SingleTemplateItem data={item}
                    previewTemplate={() => {
                        setSelectedTemplate(item);
                    }}
                    editTemplate={() => {
                        setEditTemplate(item);
                    }}
                />
            })}
        </div>}

        <div style={{ height: 20 }}></div>
        {isSuccess && total > per_page && <Pagination current={page_no} onChange={new_page_no => setPageNo(new_page_no)} total={total} pageSize={per_page}
            showTitle={false} showSizeChanger={false}
        />}

        {selected_template && <PreviewTemplate
            open={true}
            setOpen={(val) => {
                if (!val) {
                    setSelectedTemplate(null);
                }
            }}
            is_template={true}
            slug_field="id"
            isMobile={isMobile}
            item={selected_template}
            enable_nav={false}
            me={me}
        />}

        {edit_template && <TemplateEditDialog
            open={true}
            setOpen={(val) => {
                if (!val) {
                    setEditTemplate(null);
                }
            }}
            item={edit_template}
            isMobile={isMobile}
            me={me}
        />}
    </div>
}

export default TemplatesGrid;