import React, { useState } from "react";
import CreateNewCard from "./CreateNewCard";
import CreationDisplayArea from "./CreationDisplayArea";
import { Layout } from "antd";
import QurealSection from "../common/QurealSection";
import log from "cslog"
import NoticeCard from "../common/NoticeCard";
import AI_BOT_NOTICE_IMG from "../media/imgs/qureal_ai_bot_notice_pic.png";
import AI_ROBOT from "../media/imgs/cute_ai_bot.png"

const { Content } = Layout;


function CreationsHome({ me }) {
    const [type, setType] = useState("all_creation");

    log.d(me, "Me in CreationsHome")
    return (
        <QurealSection>
            <NoticeCard
                title="Qureal AI bot is coming soon..."
                content="Create anyting from social media posts, business documents, e-magazines to website on chat interface."
                height="120px"
                left_img_src={AI_ROBOT}
                right_img_src={AI_BOT_NOTICE_IMG}
            />
            <div
                style={{
                    minHeight: "100vh",
                    display: "flex",
                    flexDirection: "column",
                    // border: "1px dotted red"
                }}
            >
                <CreateNewCard
                    selected_type={type}
                    setSelectedType={setType}
                    me={me}
                />
                <CreationDisplayArea type={type} me={me} />
            </div>
        </QurealSection>
    );
}

export default CreationsHome;
