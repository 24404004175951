import React from "react";
import QurealSection from "../common/QurealSection";
import SectionHeader from "../components/commons/SectionHeader";
import { Segmented } from "antd";
import styles from "./styles/AIImageGen.module.css";


function AIImageGen() {

    return <QurealSection header={<SectionHeader heading="Templates Manager" />}>
        <div className={styles.wrapper}>
            <div className={styles.sidebar}>
                <p className={styles.label}>Aspect Ratio</p>
                <AspectRatioPicker />
            </div>
            <div className={styles.content}>
                <h1>Content</h1>
            </div>
        </div>
    </QurealSection>
}

export default AIImageGen;


function AspectRatioPicker() {
    return <div className={styles.grid_wrapper}>
        <div className={styles.grid_item}>
            <div className={styles.ar_square}></div>
        </div>
        <div className={styles.grid_item}>
            <div className={styles.ar_landscape}></div>
        </div>
        <div className={styles.grid_item}>
            <div className={styles.ar_portrait}></div>
        </div>
    </div>
}